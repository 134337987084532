export default {
  "Nav": {
    "links": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/commands"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/stats"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poll"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/donate"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volunteer_activism"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://discord.gg/qPrqVwR"])},
        "href": true,
        "blank": true,
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discord"])}
      }
    ],
    "login": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])}
    }
  },
  "Footer": {
    "links": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["top.gg"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://top.gg/bot/705372408281825350"])},
        "blank": true,
        "block": 1
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SDC"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://bots.server-discord.com/705372408281825350"])},
        "blank": true,
        "block": 1
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boticord.top"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://boticord.top/bot/705372408281825350"])},
        "blank": true,
        "block": 1
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://docs.ufobot.ru"])},
        "blank": true,
        "block": 2
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support server"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://discord.gg/qPrqVwR"])},
        "blank": true,
        "block": 2
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/donate"])},
        "block": 2
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/terms"])},
        "block": 3
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/privacy"])},
        "block": 3
      }
    ],
    "darkTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark theme"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights were abducted"])}
  },
  "DurationPicker": {
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
    "durationInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration input"])},
    "enterDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter duration"])},
    "units": {
      "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconds"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])}
    },
    "invalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number must be a positive integer"])},
    "tooLongDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too long duration"])}
  },
  "TemplateInput": {
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message template"])},
    "tooltips": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role mention"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel mention"])},
      "variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
      "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])}
    },
    "variables": {
      "member": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member ID"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.username"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member username"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.globalName"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member display name"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.nickname"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member nickname (display name if no nickname)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.avatarUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member avatar URL"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.bannerUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member banner URL"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.bot"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether member is a bot (condition can be used)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.mention"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member mention"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member account creation date"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.joined"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member join date"])}
          }
        ]
      },
      "guild": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server ID"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.name"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server name"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.memberCount"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server members count"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.iconUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server icon URL"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.bannerUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server banner URL"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server creation date"])}
          }
        ]
      },
      "channel": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel ID"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.name"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel name"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.topic"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel topic"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.mention"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel mention"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel creation date"])}
          }
        ]
      },
      "moderator": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator ID"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.username"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator username"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.globalName"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator display name"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.nickname"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator nickname (display name if no nickname)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.avatarUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator avatar URL"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.bannerUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator banner URL"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.bot"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether moderator is a bot (condition can be used)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.mention"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator mention"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator account creation date"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.joined"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator join date"])}
          }
        ]
      },
      "punishment": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punishment.duration"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment duration (null if not specified)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punishment.ends"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment expiration date (null if not specified)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punishment.reason"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment reason"])}
          }
        ]
      },
      "work": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work.salary"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of earned money"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work.balance"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member balance after work"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work.moneySymbol"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server money symbol"])}
          }
        ]
      }
    },
    "errors": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter template"])},
      "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template cannot be longer than [length] characters"])}
    }
  },
  "TemplateCompilationError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while checking the templates. Check that all variables are used correctly. Template variables documentation is"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])}
  },
  "EmbedInput": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embed"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "generalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use template variables in every embed option. For example, in icon url you can use member avatar url variable"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "embed": {
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "author": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author Url"])},
        "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author icon Url"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title Url"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "field": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field value"])},
        "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inline"])}
      },
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Url"])},
      "thumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbnail Url"])},
      "footer": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer"])},
        "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer icon Url"])}
      },
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])}
    },
    "errors": {
      "nameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter field name"])},
      "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot be longer than [length] characters"])}
    }
  },
  "TimestampInput": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
    "items": {
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current time"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select variable"])}
    },
    "variables": {
      "memberCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member account creation date"])},
      "memberJoined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member join date"])},
      "guildCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server creation date"])},
      "punishmentEnds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment expiration date (null if not specified)"])}
    }
  },
  "ResetBalance": {
    "resetBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset all balances"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENTION!"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will reset balances of all members. This is irreversible. Are you sure?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter server name"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "BoostAlert": {
    "boost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate us"])}
  },
  "CustomJobs": {
    "subtitles": {
      "deleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete job"])},
      "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete custom job [J]? This action is irreversible"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "createItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create job"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job ending message"])},
      "messageHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to set different messages for positive and negative salary values, use comparative conditions"])},
      "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Url"])},
      "iconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to set job icon that will be displayed when selecting it"])},
      "hideOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide options"])},
      "hideOptionsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If selected, all job options will not be displayed to members"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
      "salaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set both positive and negative salary values"])},
      "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
      "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
      "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
      "cooldown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown"])},
      "requiredXp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required XP"])},
      "requiredRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required roles"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate us"])},
      "increaseLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to increase jobs limit to"])},
      "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs limit reached"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
    },
    "errors": {
      "invNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an integer"])},
      "invJobName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job must have a name not longer than 50 characters"])},
      "invJobDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description may not be longer than 1000 characters"])},
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another job has this name"])},
      "invUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Url"])}
    }
  },
  "Items": {
    "subtitles": {
      "deleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete item"])},
      "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete item [I]? This action is irreversible"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "createItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create item"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Url"])},
      "iconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to set an item icon that will be displayed in item info and when buying it"])},
      "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "requiredXp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required XP"])},
      "xp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given XP"])},
      "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
      "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add role"])},
      "requiredRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required roles"])},
      "removeRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove role"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate us"])},
      "increaseLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to increase items limit to"])},
      "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items limit reached"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
    },
    "errors": {
      "invPosNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a positive integer"])},
      "invItemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item must have a name not longer than 50 characters"])},
      "invItemDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description may not be longer than 200 characters"])},
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another item has this name"])},
      "invUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Url"])}
    }
  },
  "Home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UFO"])},
    "descriptions": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown flying Discord bot"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["490 million rubles were allocated for new logo"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look what we found in Mogilev region"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliens will not destroy this planet"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added more angle of attack for aerodynamics"])}
    ],
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "featuresTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "features": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members can earn money and spend them in the shop. Who will become the most rich and experienced?"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In casino games you can have fun and bet your economy money. Please do not scream if you lose all your money!"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilities"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot contains several useful utilities that can help you in server management and communication"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot can log messages deletions and edits and all server moderation actions"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punish malicious violators of your server rules with warns, mutes, kicks and bans"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automoderation"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And for some violations, the bot can punish members automatically!"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greetings"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UFO will gladly greet new server members in channel and in DMs and give them roles"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bot has a convenient and intuitive control panel where you can customize the most of the features"])}
      }
    ]
  },
  "Commands": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
    "categories": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
      "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
      "utilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilities"])},
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])}
    },
    "boostRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UFO Boost required"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
    "aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliases"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required permissions"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "Stats": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot statistics"])},
    "guilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servers"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
    "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis"])},
    "ping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ping"])},
    "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
    "shards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shards"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ms"])}
  },
  "Donate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support us to get UFO Boosts! You can activate them on servers with command <code>boost</code><br>Activating the boost on the server opens bonus features on it:"])},
    "features": [
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience for messages"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set amount of experience that will be given to members for messages with certain chance"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishments DM messages"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set message with template variables and embeds that will be sent to kicked/banned members id DM"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money bonuses"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set daily and weekly bonuses on the server"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["art_track"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons for items and jobs"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set icons for items and custom jobs that will be displayed in information about them and when buying/working. Displayed as embed thumbnail"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moving"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased limits"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit of items in the shop - 30 (instead of 15), custom jobs - 15 (instead of 5), giveaways - 15 (instead of 5), coupons - 15 (instead of 5)"])}
      }
    ],
    "cards": {
      "standard": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
        "perks": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boosts for 2 servers"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role [R] on support server with permission to change nickname"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to secret channel"])}
        ],
        "price": 59
      },
      "premium": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "perks": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boosts for 4 servers"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role [R] on support server with permission to view audit log"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to secret channel"])}
        ],
        "price": 99
      }
    },
    "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/month"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "Landing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for inviting!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for inviting UFO!"])},
    "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "Me": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG OUT"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "leaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaders"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "noGuilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No servers to add or configure the bot"])},
    "badges": {
      "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
      "head_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head Staff"])},
      "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "tester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tester"])},
      "donator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donator"])},
      "premium_donator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium donator"])},
      "bug_hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug hunter"])}
    }
  },
  "Guild": {
    "links": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["economy"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attach_money"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commands"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderation"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logs"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greetings"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["greetings"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["person_add"])}
      }
    ],
    "boost": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost"])},
      "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boost"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grade"])}
    },
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted!"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "GuildGeneral": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "subtitles": {
      "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])}
    },
    "errors": {
      "noPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter prefix"])},
      "prefixLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix cannot be longer than 20 characters"])},
      "invalidChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix contains invalid characters"])}
    },
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "GuildEconomy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
    "subtitles": {
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
      "moneybags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneybags"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
      "cooldown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown"])},
      "money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money"])},
      "moneySymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money symbol"])},
      "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
      "moneySymbolHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use emoji, enter it in <:name:id> format"])},
      "minBet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimal bet"])},
      "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet"])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
      "boostDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to set up experience that will be given to members for messages, as well as daily and weekly money bonuses"])},
      "messageXp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience for messages"])},
      "chance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chance"])},
      "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
      "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
      "moneyBonuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money bonuses"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "resetBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset all balances"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
      "customJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom jobs"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percent"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])}
    },
    "errors": {
      "invPosNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a positive integer"])},
      "invNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an integer"])},
      "invMoneySymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money symbol may not be longer than 50 characters"])},
      "invPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid percentage"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "GuildCommands": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
    "categories": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
      "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
      "utilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilities"])},
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])}
    },
    "subtitles": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "deleteUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete usage"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "allowedRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed roles"])},
      "forbiddenRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden roles"])},
      "allowedChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed channels"])},
      "forbiddenChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden channels"])}
    },
    "permissionsLabel": {
      "allowedRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed roles"])},
      "forbiddenRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden roles"])},
      "allowedChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed channels"])},
      "forbiddenChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden channels"])}
    },
    "permissionsHint": {
      "allowedRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members with these roles can use this command (all if empty)"])},
      "forbiddenRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members with these roles cannot use this command"])},
      "allowedChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members can use this command in these channels (all if empty)"])},
      "forbiddenChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members cannot use this command in these channels"])}
    },
    "errors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to overwrite permissions for slash command because application has no permission to do this. Authorize via"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new link"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and try again"])}
    }
  },
  "GuildModeration": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
    "subtitles": {
      "muteRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute role"])},
      "selectRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select role"])},
      "useTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use timeout"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
      "timeoutDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muted members will also be sent to a timeout. If mute duration is 28 days or longer, timeout will not be issued."])},
      "warnsPunishments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishments for warns"])},
      "warns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warns"])},
      "punishment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "autoModeration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automoderation"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work?"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "deleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete messages"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
      "selectChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to certain channel"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "punishMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punish member"])},
      "whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White list"])},
      "allowedRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed roles"])},
      "allowedChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed channels"])},
      "allowedGuilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed servers (Server IDs or invite codes)"])},
      "maxMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max messages per minute"])},
      "maxSymbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max characters per minute"])},
      "boostDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to set custom messages that will be send to kicked and banned via command members in DM"])},
      "kickMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom DM to kicked members"])},
      "banMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom DM to banned members"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
      "testsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test messages"])},
      "testsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sends to you selected message in DM for you to check that messages, embeds and all templates are filled correctly. UFO bot will be used as a moderator variable. Ban duration will be 1 hour. Save all changes before testing."])},
      "kickTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick test"])},
      "banTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban test"])},
      "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])},
      "testCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test completed!"])},
      "testError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "punishments": {
      "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warn"])},
      "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute"])},
      "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
      "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])}
    },
    "autoModeration": {
      "invites": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invites"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This filter is triggered by any valid invite link to Discord server. You can enter server IDs and invite codes that will be ignored."])}
      },
      "flood": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flood"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This filter is triggered by flood of two types: first, consisting of a large number of messages, and second, consisting of messages containing a large number of characters. If specified number of messages or sum of characters in messages will be exceeded by user in less than one minute, filter will be triggered. We recommend using default values (20 messages and 3000 characters) and changing them if necessary"])}
      }
    },
    "errors": {
      "reasonLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason cannot be longer than 512 characters"])},
      "invalidDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration must be a positive integer"])},
      "tooLongDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment duration is too long"])},
      "invPosNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a positive integer"])},
      "invalidWarns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warns amount must be an integer"])},
      "duplicateWarns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishment for this count of warns is already set"])},
      "warnsPunishmentsLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punishments for warns limit is reached"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "GuildLogs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
    "subtitles": {
      "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "ignoredChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignored channels"])},
      "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
      "ignoredChannelsHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages logs will ignore these channels"])}
    },
    "categories": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])}
    },
    "logs": {
      "messageDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message delete"])},
      "messageEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message edit"])},
      "messageDeleteBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages clear"])},
      "roleCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role create"])},
      "roleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role edit"])},
      "roleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role delete"])},
      "moderationWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member warn"])},
      "moderationMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member mute"])},
      "moderationKick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member kick"])},
      "moderationBan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member ban"])},
      "moderationUnmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member unmute"])},
      "moderationUnban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member unban"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "GuildGreetings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greetings"])},
    "subtitles": {
      "joinMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join message"])},
      "leaveMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave message"])},
      "joinDM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DM greeting"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "messageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message template"])},
      "joinRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join roles"])},
      "selectRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select roles"])},
      "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
      "testsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test greetings"])},
      "testsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulates event of your joining or leaving the server for you to check that messages, embeds and all templates are filled correctly. Save all changes before testing."])},
      "joinTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join test"])},
      "leaveTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave test"])},
      "testCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test completed!"])},
      "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])},
      "testError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "Leaderboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "xp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XP"])}
  },
  "LeaderboardMember": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard rank"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "xp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XP"])},
    "resetXP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset XP"])},
    "deleteMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete member"])},
    "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete [M] from server leaderboard?"])},
    "errors": {
      "invalidBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance must be an integer"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "Terms": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "paragraphs": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms of Service (hereinafter referred to as the – «Terms») govern the use of the Discord bot UFO (hereinafter referred to as the – «Bot») and the website <a class='primary-link' href='https://ufobot.ru'>https://ufobot.ru</a> (hereinafter referred to as the – «Bot»). By using the Bot and/or the Site, you agree to these Terms."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The administration reserves the right to change these Terms at any time without prior notice. The updated Terms come into force from the moment they are published on the Site."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of services"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bot provides users with the opportunity to receive a variety of information and services within the Discord server. The functionality of the Bot may include, but is not limited to: informational notifications, entertainment functions, assistance in server management."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Site provides information about the Bot, instructions for its use and helps to interact with users."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration and account"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use the Bot, you may need to log in with your Discord account. Using the Bot functionality implies that you agree to Discord's privacy policy."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The administration of the Bot and the Site is not responsible for any direct or indirect damage caused to users as a result of the use or inability to use the services of the Bot and/or the Site."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Administration does not guarantee uninterrupted and error-free operation of the Bot and the Site. All services are provided on an \"as is\" basis."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User content"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are responsible for any content they post, upload or otherwise make available through the Bot and/or the Site."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The administration reserves the right to remove any content that, in its opinion, violates these Terms or is illegal, offensive, threatening or inappropriate."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All personal information provided by users is processed in accordance with the <a class='primary-link' href='/privacy'>Privacy Policy</a>, posted on the Site."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using the Bot and/or the Site, you agree to the terms of our Privacy Policy."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intellectual property"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All materials and content available on the Site and through the Bot, including text, graphics, logos and software, are the property of the administration or its licensors and are protected by copyright and intellectual property laws."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users do not have the right to copy, distribute or create derivative works based on the content without the written permission of the administration."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes and termination of service"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The administration reserves the right to change, suspend or terminate the provision of services to the Bot and/or the Site at any time without prior notice."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The User may stop using the Bot and/or the Site at any time."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governing Law"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms are governed by and construed in accordance with the laws of the Russian Federation."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any questions related to these Terms, you can contact us on our <a class='primary-link' href='https://discord.gg/qPrqVwR'>support server</a> on Discord."])}
        ]
      }
    ]
  },
  "Privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "paragraphs": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Privacy Policy (hereinafter referred to as the “Policy”) determines the procedure for processing and protecting personal data of users of the Discord bot UFO (hereinafter referred to as the “Bot”) and the website <a class='primary-link' href='https://ufobot.ru' >https://ufobot.ru</a> (hereinafter referred to as the “Site”)."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using the Bot and/or the Site, you consent to the processing of your personal data in accordance with this Policy."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The administration reserves the right to change this Policy at any time without prior notice. The updated Policy comes into force from the moment it is published on the Site."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection of personal data"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We collect the following categories of personal data: Discord account information (user ID); contact information provided by you; information about the Discord server (IDs of server, roles, channels, server members)."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not collect special categories of personal data (for example, message content)."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of personal data"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data is used to provide the functionality of the Bot and the Website."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not share your personal data with third parties, except as required by law or when necessary to provide services (for example, to process payments)."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection of personal data"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We take all necessary organizational and technical measures to protect your personal data from unauthorized or accidental access, destruction, modification, blocking, copying and distribution."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to personal data is provided only to authorized employees who are required to maintain the confidentiality of this information."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage of personal data"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data is stored for the time necessary to achieve the purposes for which it was collected or for the period established by the law."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the purposes of processing have been achieved or at your request, personal data will be deleted."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User rights"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the right to: erase your personal data; restrict the processing of your personal data."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To exercise your rights, you can contact us on the <a class='primary-link' href='https://discord.gg/qPrqVwR'>support server</a>."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Cookies to improve the functionality of the Site."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can customize the use of Cookies in your browser settings. However, disabling Cookies may affect the functionality of some features of the Site."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third party resources"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Site and Bot may contain links to third-party resources, for the content and privacy policies of which we are not responsible."])}
        ]
      }
    ]
  },
  "Appeal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban appeal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban appeal"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer all the questions below. Please note that you can appeal only once. If it is declined, you will no longer be able to submit it."])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appeal has been submitted, wait for it to be reviewed. If it is approved, the bot or moderator will notify you via DM."])},
    "errors": {
      "notBanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not banned on support server"])},
      "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your have already submitted an appeal, wait for it to be reviewed"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your appeal has been declined"])},
      "invalidAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer length must be from 1 to 500 characters"])},
      "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while submitting, please reload the page"])}
    }
  },
  "V2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version 2.0.0"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "Login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logging in..."])}
  },
  "NotFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You flew into outer space"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])}
  }
}