export default {
  "Nav": {
    "links": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команды"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/commands"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/stats"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poll"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Донат"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/donate"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volunteer_activism"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://discord.gg/qPrqVwR"])},
        "href": true,
        "blank": true,
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discord"])}
      }
    ],
    "login": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])}
    }
  },
  "Footer": {
    "links": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["top.gg"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://top.gg/bot/705372408281825350"])},
        "blank": true,
        "block": 1
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SDC"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://bots.server-discord.com/705372408281825350"])},
        "blank": true,
        "block": 1
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boticord.top"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://boticord.top/bot/705372408281825350"])},
        "blank": true,
        "block": 1
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документация"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://docs.ufobot.ru"])},
        "blank": true,
        "block": 2
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер поддержки"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://discord.gg/qPrqVwR"])},
        "blank": true,
        "block": 2
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Донат"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/donate"])},
        "block": 2
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/terms"])},
        "block": 3
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфиденциальность"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/privacy"])},
        "block": 3
      }
    ],
    "darkTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темная тема"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все права похитили"])}
  },
  "DurationPicker": {
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввести"])},
    "durationInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод длительности"])},
    "enterDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите длительность"])},
    "units": {
      "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секунды"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минуты"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часы"])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни"])}
    },
    "invalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Число должно быть целым и положительным"])},
    "tooLongDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком большая длительность"])}
  },
  "TemplateInput": {
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон сообщения"])},
    "tooltips": {
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминание роли"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминание канала"])},
      "variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переменная"])},
      "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документация"])}
    },
    "variables": {
      "member": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID участника"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.username"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя участника"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.globalName"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображаемое имя участника"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.nickname"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никнейм участника (отображаемое имя, если нет никнейма)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.avatarUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на аватар участника"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.bannerUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на баннер участника"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.bot"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Является ли участник ботом (можно использовать условие)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.mention"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминание участника"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания аккаунта участника"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member.joined"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата входа участника на сервер"])}
          }
        ]
      },
      "guild": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID сервера"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.name"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название сервера"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.memberCount"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество участников сервера"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.iconUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на иконку сервера"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.bannerUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на баннер сервера"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guild.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания сервера"])}
          }
        ]
      },
      "channel": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID канала"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.name"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название канала"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.topic"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема канала"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.mention"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминание канала"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["channel.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания канала"])}
          }
        ]
      },
      "moderator": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модератор"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.id"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID модератора"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.username"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя модератора"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.globalName"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображаемое имя модератора"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.nickname"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никнейм модератора (отображаемое имя, если нет никнейма)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.avatarUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на аватар модератора"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.bannerUrl"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на баннер модератора"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.bot"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Является ли модератор ботом (можно использовать условие)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.mention"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминание модератора"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.created"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания аккаунта модератора"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderator.joined"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата входа модератора на сервер"])}
          }
        ]
      },
      "punishment": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наказание"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punishment.duration"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность наказания (null если отсутствует)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punishment.ends"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания наказания (null если отсутствует)"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punishment.reason"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина наказания"])}
          }
        ]
      },
      "work": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])},
        "list": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work.salary"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество заработанных денег"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work.balance"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс участника после работы"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["work.moneySymbol"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ денег сервера"])}
          }
        ]
      }
    },
    "errors": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите шаблон"])},
      "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина шаблона не может быть больше [length] символов"])}
    }
  },
  "TemplateCompilationError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при проверке шаблонов. Проверьте, что все переменные использованы правильно. Документация по переменным шаблона находится"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["здесь"])}
  },
  "EmbedInput": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмбед"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
    "generalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете использовать переменные шаблона абсолютно в каждом параметре эмбеда. Например, в ссылке на иконку можно использовать переменную аватара участника"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
    "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
    "embed": {
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
      "author": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на автора"])},
        "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на иконку автора"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка названия"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "field": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название поля"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание поля"])},
        "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ряд"])}
      },
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на большую картинку"])},
      "thumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на маленькую картику"])},
      "footer": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Футер"])},
        "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на иконку футера"])}
      },
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметка времени"])}
    },
    "errors": {
      "nameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название поля"])},
      "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не может быть длиннее [length] символов"])}
    }
  },
  "TimestampInput": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметка времени"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переменная"])},
    "items": {
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущее время"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор даты"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать переменную"])}
    },
    "variables": {
      "memberCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания аккаунта участника"])},
      "memberJoined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата присоединения участника"])},
      "guildCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания сервера"])},
      "punishmentEnds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания наказания (null если отсутствует)"])}
    }
  },
  "ResetBalance": {
    "resetBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить все балансы"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВНИМАНИЕ!"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данное действие обнулит балансы всех участников сервера. Это не может быть отменено. Вы действительно хотите продолжить?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название сервера"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
  },
  "BoostAlert": {
    "boost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буст"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержите нас"])}
  },
  "CustomJobs": {
    "subtitles": {
      "deleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить работу"])},
      "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить пользовательскую работу [J]? Это действие не может быть отменено"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "createItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать работу"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение по окончании работы"])},
      "messageHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если хотите установить разные сообщения для положительных и отрицательных значений зарплаты, используйте сравнительные условия"])},
      "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на иконку"])},
      "iconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы установить иконку работы, которая будет показываться при ее выборе"])},
      "hideOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрывать параметры"])},
      "hideOptionsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если выбрано, все параметры работы, кроме описания, показываться участникам не будут"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрывать"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарплата"])},
      "salaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете устанавливать как положительные, так и отрицательные значения зарплаты"])},
      "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
      "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
      "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значения"])},
      "cooldown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кулдаун"])},
      "requiredXp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимый опыт"])},
      "requiredRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимые роли"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержите нас"])},
      "increaseLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы увеличить лимит работ до"])},
      "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнут лимит работ"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
    },
    "errors": {
      "invNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите целое число"])},
      "invJobName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа должна иметь название, не длиннее 50 символов"])},
      "invJobDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание не может быть длиннее 1000 символов"])},
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с таким названием уже существует"])},
      "invUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительную ссылку"])}
    }
  },
  "Items": {
    "subtitles": {
      "deleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить товар"])},
      "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить товар [I]? Это действие не может быть отменено"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "createItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать товар"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "iconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы установить иконку товара, которая будет показываться в информации о нем и при покупке"])},
      "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на иконку"])},
      "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значения"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
      "requiredXp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимый опыт"])},
      "xp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдаваемый опыт"])},
      "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
      "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
      "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дать роль"])},
      "requiredRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимые роли"])},
      "removeRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снять роль"])},
      "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержите нас"])},
      "increaseLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы увеличить лимит товаров до"])},
      "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнут лимит товаров"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
    },
    "errors": {
      "invPosNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите целое положительное число"])},
      "invItemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товар должен иметь название, не длиннее 50 символов"])},
      "invItemDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание товара не может быть длиннее 200 символов"])},
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товар с таким названием уже существует"])},
      "invUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительную ссылку"])}
    }
  },
  "Home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UFO"])},
    "descriptions": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неопознанный летающий Discord бот"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На новый логотип было выделено 490 млн рублей"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотрите, что мы нашли в Могилёвской области"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пришельцы передумали уничтожать эту планету"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для аэродинамичности тарелке добавлен угол наклона"])}
    ],
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить"])},
    "featuresTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функционал"])},
    "features": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономика"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники смогут зарабатывать деньги и тратить их на покупку товаров в магазине. Кто же окажется самым богатым и опытным?"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игры"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В казино играх можно развлечься и поставить заработанные в экономике деньги. Просим сильно не кричать, если вы проиграете все свое состояние!"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утилиты"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот содержит несколько полезных утилит, которые могут значительно помочь в управлении сервером и общении"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логи"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот может логировать удаления и изменения сообщений, а также все модераторские действия, происходящие на сервере"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наказывайте злостных нарушителей правил вашего сервера предупреждениями, мутами, киками и банами"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автомодерация"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["А за некоторые нарушения бот может наказывать участников сам!"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствия"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UFO с радостью поприветствует новых участников сервера в канале и в ЛС и выдаст им роли при входе"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У бота есть удобная и понятная панель управления, которая позволяет кастомизировать большинство функций"])}
      }
    ]
  },
  "Commands": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команды"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команды"])},
    "categories": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономика"])},
      "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игры"])},
      "utilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утилиты"])},
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация"])}
    },
    "boostRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется UFO Boost"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
    "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использование"])},
    "aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Псевдонимы (алиасы)"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимые права"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
  },
  "Stats": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика бота"])},
    "guilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серверов"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователей"])},
    "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналов"])},
    "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмодзи"])},
    "ping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пинг"])},
    "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База данных"])},
    "shards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шарды"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "shardsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шардов на страницу"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мс"])}
  },
  "Donate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Донат"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Донат"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержите нас чтобы получить UFO бусты! Их вы сможете активировать на любых серверах командой <code>буст</code><br>Активация буста на сервере открывает на нем бонусные функции:"])},
    "features": [
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт за сообщения"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте количество опыта, которое будет выдаваться участникам за сообщения с определенной вероятностью"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщения в ЛС при наказании"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте сообщения с переменными шаблона и эмбедами, которые будут отправляться участникам при кике/бане в личку"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денежные бонусы"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте ежедневный и еженедельный бонусы на сервере"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["art_track"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иконки в товарах и работах"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите иконки для товаров и пользовательских работ, которые будут отображаться в информации о них и при покупке/работе. Отображаются как маленькая картинка в углу эмбеда"])}
      },
      {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moving"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увеличенные лимиты"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит товаров в магазине - 30 (вместо 15), пользовательских работ - 15 (вместо 5), розыгрышей - 15 (вместо 5), купонов - 15 (вместо 5)"])}
      }
    ],
    "cards": {
      "standard": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандарт"])},
        "perks": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бусты для 2 серверов"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль [R] на сервере поддержки с правом изменить никнейм"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ в скрытый канал"])}
        ],
        "price": 59
      },
      "premium": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премиум"])},
        "perks": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бусты для 4 серверов"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль [R] на сервере поддержки с правом на просмотр журнала аудита"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ в скрытый канал"])}
        ],
        "price": 99
      }
    },
    "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/месяц"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не авторизован"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
  },
  "Landing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за добавление!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за добавление UFO!"])},
    "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команды"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
  },
  "Me": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВЫЙТИ"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "leaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лидеры"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать"])},
    "noGuilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет серверов для добавления и настройки бота"])},
    "badges": {
      "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработчик"])},
      "head_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глава персонала"])},
      "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модератор"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
      "tester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестер"])},
      "donator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Донатор"])},
      "premium_donator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премиум донатор"])},
      "bug_hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Охотник за багами"])}
    }
  },
  "Guild": {
    "links": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономика"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["economy"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attach_money"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команды"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commands"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moderation"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логи"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logs"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["article"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствия"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["greetings"])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["person_add"])}
      }
    ],
    "boost": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буст"])},
      "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boost"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grade"])}
    },
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка..."])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранено!"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
  },
  "GuildGeneral": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
    "subtitles": {
      "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Префикс"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
      "commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команд"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерфейса"])}
    },
    "errors": {
      "noPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите префикс"])},
      "prefixLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина префикса не должна превышать 20 символов"])},
      "invalidChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Префикс содержит недопустимые символы"])}
    },
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
  },
  "GuildEconomy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономика"])},
    "subtitles": {
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])},
      "moneybags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мешки"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарплата"])},
      "cooldown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кулдаун"])},
      "money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деньги"])},
      "moneySymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ денег"])},
      "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])},
      "moneySymbolHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы использовать эмодзи, введите его в формате <:имя:id>"])},
      "minBet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная ставка"])},
      "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка"])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
      "boostDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите установить опыт, который будет начисляться участникам за отправку сообщений, а также ежедневные и еженедельные денежные бонусы?"])},
      "messageXp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт за сообщения"])},
      "chance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вероятность"])},
      "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
      "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
      "moneyBonuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денежные бонусы"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневный"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еженедельный"])},
      "resetBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить все балансы"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
      "customJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользовательские работы"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])}
    },
    "errors": {
      "invPosNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите целое положительное число"])},
      "invNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите целое число"])},
      "invMoneySymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ денег не может быть длиннее 50 символов"])},
      "invPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительный процент"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
  },
  "GuildCommands": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команды"])},
    "categories": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономика"])},
      "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игры"])},
      "utilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утилиты"])},
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация"])}
    },
    "subtitles": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "deleteUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалять использование"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Права"])}
    },
    "permissionsLabel": {
      "allowedRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные роли"])},
      "forbiddenRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрещенные роли"])},
      "allowedChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные каналы"])},
      "forbiddenChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрещенные каналы"])}
    },
    "permissionsHint": {
      "allowedRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли, с которыми участники смогут использовать эту команду (все, если не указано)"])},
      "forbiddenRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли, с которыми участники не смогут использовать эту команду"])},
      "allowedChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы, в которых участники смогут использовать эту команду (все, если не указано)"])},
      "forbiddenChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы, в которых участники не смогут использовать эту команду"])}
    },
    "errors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось переопределить права для слеш-команды, потому что у приложения отсутствует право на это. Авторизуйтесь по"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["новой ссылке"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и повторите попытку"])}
    }
  },
  "GuildModeration": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация"])},
    "subtitles": {
      "muteRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль мута"])},
      "selectRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать роль"])},
      "useTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать тайм-аут"])},
      "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тайм-аут"])},
      "timeoutDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участника в случае мута будет также отправлять в тайм-аут. Если длительность мута будет 28 дней и больше, тайм-аут участнику выдан не будет."])},
      "warnsPunishments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наказания за предупреждения"])},
      "warns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждения"])},
      "punishment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наказание"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность"])},
      "autoModeration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автомодерация"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает?"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "deleteMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалять сообщения"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])},
      "selectChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить в определенный канал"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
      "punishMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наказать участника"])},
      "defaultChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал нарушения"])},
      "whitelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белый список"])},
      "allowedRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные роли"])},
      "allowedChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные каналы"])},
      "allowedGuilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенные сервера (ID серверов или коды приглашений)"])},
      "maxMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. сообщений в минуту"])},
      "maxSymbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. символов в минуту"])},
      "boostDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите настроить пользовательские сообщения, которые будут отправляться в ЛС пользователям при кике и бане через команду?"])},
      "kickMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение в ЛС кикнутым участникам"])},
      "banMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение в ЛС забаненным участникам"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включено"])},
      "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
      "testsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протестировать сообщения"])},
      "testsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляет вам в ЛС выбранное сообщение для проверки правильности заполнения сообщений, эмбедов и всех шаблонов. В качестве переменной moderator будет использован бот UFO. Длительность бана будет 1 час. Перед запуском тестов сохраните все изменения."])},
      "kickTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест кика"])},
      "banTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест бана"])},
      "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите..."])},
      "testCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест выполнен!"])},
      "testError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
    },
    "punishments": {
      "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждение"])},
      "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мут"])},
      "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кик"])},
      "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бан"])}
    },
    "autoModeration": {
      "invites": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашения"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный фильтр реагирует на любые действительные ссылки-приглашения на Discord сервера. Вы можете указать ID серверов или коды приглашений, на которые бот реагировать не будет."])}
      },
      "flood": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Флуд"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный фильтр реагирует на флуд двух типов: первый, состоящий из большого количества сообщений, и второй, состоящий из сообщений, содержащих большое количество символов. Если указанное ниже количество сообщений или указанная сумма всех символов в сообщениях будет превышена пользозвателем меньше чем за минуту, бот отреагирует на это. Рекомендуем использовать значения по умолчанию (20 сообщений и 3000 символов) и изменять их при необходимости."])}
      }
    },
    "errors": {
      "reasonLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина не должна быть длиннее 512 символов"])},
      "invalidDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность должна быть целым положительным числом"])},
      "tooLongDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком большая длительность наказания"])},
      "invPosNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите целое положительное число"])},
      "invalidWarns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество предупреждений должно быть целым положительным числом"])},
      "duplicateWarns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наказание за такое количество предупреждений уже установлено"])},
      "warnsPunishmentsLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнут лимит наказаний за предупреждения"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
  },
  "GuildLogs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логи"])},
    "subtitles": {
      "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логи"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
      "ignoredChannels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнорируемые каналы"])},
      "channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы"])},
      "ignoredChannelsHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логи сообщений будут игнорировать эти каналы"])}
    },
    "categories": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщения"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация"])}
    },
    "logs": {
      "messageDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление сообщений"])},
      "messageEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение сообщений"])},
      "messageDeleteBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистка сообщений"])},
      "roleCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание ролей"])},
      "roleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение ролей"])},
      "roleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление ролей"])},
      "moderationWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждение участника"])},
      "moderationMute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мут участника"])},
      "moderationKick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кик участника"])},
      "moderationBan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бан участника"])},
      "moderationUnmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размут участника"])},
      "moderationUnban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбан участника"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
  },
  "GuildGreetings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствия"])},
    "subtitles": {
      "joinMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение о входе"])},
      "leaveMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение о выходе"])},
      "joinDM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствие в ЛС"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включено"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
      "messageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон сообщения"])},
      "joinRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли при входе"])},
      "selectRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать роли"])},
      "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
      "testsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протестировать приветствия"])},
      "testsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имитирует событие вашего входа или выхода с сервера для проверки правильности заполнения сообщений, эмбедов и всех шаблонов. Перед запуском тестов сохраните все изменения."])},
      "joinTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест входа"])},
      "leaveTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест выхода"])},
      "testCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест выполнен!"])},
      "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите..."])},
      "testError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
  },
  "Leaderboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лидеры"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка..."])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортировка"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "xp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт"])}
  },
  "LeaderboardMember": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лидеры"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место в топе"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "xp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт"])},
    "resetXP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить опыт"])},
    "deleteMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить участника"])},
    "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить [M] из таблицы лидеров сервера?"])},
    "errors": {
      "invalidBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс должен быть целым числом"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
  },
  "Terms": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
    "paragraphs": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие положения"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настоящие Условия использования (далее – «Условия») регулируют порядок использования Discord бота UFO (далее – «Бот») и сайта <a class='primary-link' href='https://ufobot.ru'>https://ufobot.ru</a> (далее – «Сайт»). Используя Бот и/или Сайт, Вы соглашаетесь с настоящими Условиями."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрация оставляет за собой право изменять настоящие Условия в любое время без предварительного уведомления. Обновленные Условия вступают в силу с момента их публикации на Сайте."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание услуг"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот предоставляет пользователям возможность получать разнообразную информацию и услуги в рамках Discord сервера. Функционал Бота может включать, но не ограничивается: информационные уведомления, развлекательные функции, помощь в управлении сервером."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт служит для предоставления информации о Боте, инструкций по его использованию, а также для взаимодействия с пользователями."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация и аккаунт"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для использования Бота может потребоваться авторизация через ваш аккаунт в Discord. Использование функционала Бота подразумевает, что Вы согласны с политикой конфиденциальности Discord."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы несете ответственность за обеспечение конфиденциальности своих учетных данных и за все действия, которые происходят под вашим аккаунтом."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение ответственности"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрация Бота и Сайта не несет ответственности за любой прямой или косвенный ущерб, причиненный пользователям в результате использования или невозможности использования услуг Бота и/или Сайта."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрация не гарантирует бесперебойную и безошибочную работу Бота и Сайта. Все услуги предоставляются \"как есть\"."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользовательский контент"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи несут полную ответственность за любой контент, который они публикуют, загружают или иным образом делают доступным через Бот и/или Сайт."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрация оставляет за собой право удалять любой контент, который, по ее мнению, нарушает настоящие Условия или является незаконным, оскорбительным, угрожающим или неприемлемым."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся личная информация, предоставленная пользователями, обрабатывается в соответствии с <a class='primary-link' href='/privacy'>Политикой конфиденциальности</a>, размещенной на Сайте."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используя Бот и/или Сайт, Вы соглашаетесь с условиями нашей Политики конфиденциальности."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеллектуальная собственность"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все материалы и контент, доступные на Сайте и через Бот, включая текст, графику, логотипы и программное обеспечение, являются собственностью администрации или ее лицензиаров и защищены законами об авторских правах и интеллектуальной собственности."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи не имеют права копировать, распространять или создавать производные работы на основе контента без письменного разрешения администрации."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение и прекращение обслуживания"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрация оставляет за собой право изменять, приостанавливать или прекращать предоставление услуг Бота и/или Сайта в любое время без предварительного уведомления."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь может прекратить использование Бота и/или Сайта в любое время."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применимое законодательство"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настоящие Условия регулируются и толкуются в соответствии с законодательством Российской Федерации."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактная информация"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По всем вопросам, связанным с настоящими Условиями, Вы можете связаться с нами на нашем <a class='primary-link' href='https://discord.gg/qPrqVwR'>сервере поддержки</a> в Discord."])}
        ]
      }
    ]
  },
  "Privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
    "paragraphs": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие положения"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настоящая Политика конфиденциальности (далее – «Политика») определяет порядок обработки и защиты персональных данных пользователей Discord бота UFO (далее – «Бот») и сайта <a class='primary-link' href='https://ufobot.ru'>https://ufobot.ru</a> (далее – «Сайт»)."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используя Бот и/или Сайт, Вы выражаете согласие на обработку ваших персональных данных в соответствии с настоящей Политикой."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрация оставляет за собой право изменять настоящую Политику в любое время без предварительного уведомления. Обновленная Политика вступает в силу с момента ее публикации на Сайте."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбор персональных данных"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы собираем следующие категории персональных данных: информация об аккаунте в Discord (идентификатор пользователя); контактная информация, предоставленная вами; информация о сервере Discord (идентификаторы: сервера, ролей, каналов, участников сервера)."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не собираем специальные категории персональных данных (например, контент сообщений)."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использование персональных данных"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональные данные используются с целью предоставления функциональности Бота и Сайта."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не передаем ваши персональные данные третьим лицам, за исключением случаев, предусмотренных законодательством или когда это необходимо для предоставления услуг (например, для обработки платежей)."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Защита персональных данных"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы принимаем все необходимые организационные и технические меры для защиты ваших персональных данных от несанкционированного или случайного доступа, уничтожения, изменения, блокирования, копирования и распространения."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к персональным данным предоставляется только уполномоченным сотрудникам, которые обязаны сохранять конфиденциальность этой информации."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хранение персональных данных"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональные данные хранятся в течение времени, необходимого для достижения целей, для которых они были собраны, или в течение срока, установленного законодательством."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После достижения целей обработки или по вашему запросу персональные данные будут удалены."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Права пользователей"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы имеете право на: удаление своих персональных данных; ограничение обработки своих персональных данных."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для реализации своих прав Вы можете связаться с нами на <a class='primary-link' href='https://discord.gg/qPrqVwR'>сервере поддержки</a>."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы cookie"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы используем файлы cookie для улучшения функциональности Сайта."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете настроить использование файлов cookie в настройках своего браузера. Однако отключение файлов cookie может повлиять на работоспособность некоторых функций Сайта."])}
        ]
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылки на сторонние ресурсы"])},
        "items": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт и Бот могут содержать ссылки на сторонние ресурсы, за содержание и политику конфиденциальности которых мы не несем ответственности."])}
        ]
      }
    ]
  },
  "Appeal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апелляция бана"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апелляция бана"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответьте на все нижеперечисленные вопросы. Учтите, что апелляцию вы можете подать только один раз. Если она будет отклонена, вы больше не сможете ее подать."])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апелляция отправлена, ожидайте ее рассмотрения. Если она будет одобрена, бот либо модератор сообщит об этом вам в ЛС."])},
    "errors": {
      "notBanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не забанены на сервере поддержки"])},
      "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже отправили апелляцию, ожидайте ее рассмотрения"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша апелляция была отклонена"])},
      "invalidAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина ответа должна быть от 1 до 500 символов"])},
      "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при отправке, пожалуйста, перезагрузите страницу"])}
    }
  },
  "V2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия 2.0.0"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
  },
  "Login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Входим в аккаунт..."])}
  },
  "NotFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница не найдена"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы улетели в открытый космос"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ГЛАВНАЯ"])}
  }
}